import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins  from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableDropAllow,
    Behaviors.DataTableResizer
  ],

  ui: {
    lecturing_by: '#lecturing_by'
  },

  events: {
    'change @ui.lecturing_by': 'reload'
  },

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [1, 'desc'], [2, 'asc'], [3, 'asc']]
    });
  },

  columns() {
    const dropapps = _.template(this.$('#dropapps').html())();
    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 40
      },
      {
        class: 'open_term',
        data : 'open_term',
        width: 65
      },
      {
        class: 'subject',
        data : 'subject'
      },
      {
        class: 'lecture',
        data : 'lecture'
      },
      {
        class   : 'records_count text-right',
        data    : 'records_count',
        sortable: false,
        width   : 70
      },
      {
        class   : 'analysis text-center',
        data    : 'id',
        sortable: false,
        width   : 80,
        render  : (val) => {
          return dropapps.replace(/:lecture_id/g, val);
        }
      }
    ];
  }
}));
