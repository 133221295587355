import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.plan_target_schedules.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns : this.columns(),
      order   : [[0, 'desc'], [1, 'desc']],
      rowGroup: {
        dataSrc: 'entry_term'
      }
    });
  },

  columns() {
    const targetMenuTemplate = _.template(this.$('#target-menu').html());
    const targetLinkTemplate = _.template(this.$('#target-link').html());

    return [
      {
        class: 'entry_term',
        data : 'entry_term',
        width: 100
      },
      {
        class: 'grade',
        data : 'grade'
      },
      {
        class: 'month',
        data : 'month'
      },
      {
        class   : 'start_grade',
        data    : 'start_grade',
        sortable: false
      },
      {
        class   : 'start_month',
        data    : 'start_month',
        sortable: false
      },
      {
        class: 'limited_grade',
        data : 'limited_grade'
      },
      {
        class: 'limited_month',
        data : 'limited_month'
      },
      {
        class   : 'plan_target_count',
        data    : 'plan_target_count',
        sortable: false,
        width   : 120,
        render  : (val, _type, row) => {
          if (!row.plan_target_links.length) {
            return '';
          }

          const targetLinks = row.plan_target_links
            .map((linkData) => {
              return targetLinkTemplate({
                link: linkData.link,
                name: linkData.name
              });
            })
            .join('');
          const targetMenu = targetMenuTemplate({ count: val, links: targetLinks });
          return targetMenu;
        }
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 50,
        render  : (value) => {
          return Utils.Render.editButton(this, value, '_self');
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (value, _type, row) => {
          if (row.plan_target_count !== '0') return '';
          return Utils.Render.deleteButton(this, value);
        }
      }
    ];
  }
}));
