import $ from 'jquery';
import { Behavior } from 'backbone.marionette';
import Utils from '../../utils';

// 内容を変更可能な督促通知ボタン
// 前提
// reload,appendSearchParamsWithoutPagingToUrl関数を持つDatatableを持つViewに適用する
// 入力状況のフィルターと通知ボタンに js クラスを適用している
// 通知ボタンのdata値として url, defaultText, enabledValue, enabledName を設定している
export default Behavior.extend({
  t: Utils.I18n.bind('behaviors.remind_notify_message'),

  ui: {
    remindFilter: '.js-remind-filter',
    remindBtn   : '.js-remind-btn'
  },

  events: {
    'click @ui.remindBtn': 'onClickRemindBtn'
  },

  onClickRemindBtn(event) {
    event.preventDefault();

    const targetBtn = $(event.currentTarget);
    const type = targetBtn.data('type');
    const url = targetBtn.data('url');
    const defaultText = targetBtn.data('defaultText');
    const enabledValue = String(targetBtn.data('enabledValue'));
    const enabledName =  String(targetBtn.data('enabledName'));
    this.ui.remindFilter.selectpicker('val', enabledValue);

    this.view.reload(({ recordsTotal: count }) => {
      if (count === 0) {
        Utils.Modal.alert(this.t(`.${type}.alert.no_students`, { enabledName }));
        return;
      }

      Utils.Modal.prompt(
        this.t(`.${type}.confirm.notify_students`, { count, enabledName }),
        (message) => {
          if (message === null) { return; }
          if (message === '') {
            Utils.Modal.alert(this.t(`.${type}.alert.required_message`));
            return;
          }

          const urlWithParams = this.view.appendSearchParamsWithoutPagingToUrl(url);
          this._notifyReminder(urlWithParams, message, type);
        },
        { counter: 50, defaultText: defaultText, noEnter: true }
      );
    });
  },

  _notifyReminder(url, message, type) {
    $.ajax({
      url,
      method: 'POST',
      data  : { title: message }
    }).then(() => {
      Utils.Notify.notify('notice', this.t(`.${type}.notice.notified_reminder`));
    });
  }
});
